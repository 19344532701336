import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`You are wonderfully unique. Consider the unlikely probability that you would
come to exist as you do, reading this now. Your parents had to exist in their
distinct forms, meet and have you precisely when they did. Their parents had to
meet. So on and so forth all the way back to abiogenesis, or creation, or
creation by means of abiogenesis; whether you trace this back with creationism
or evolution the numbers still paint you as a miracle.`}</p>
    <p>{`Then consider that every thought you've had shaped you. Every experience. The
food you eat. The air you've breathed. The places you live and the people you
interact with. Everything you do. Everything you say. Everything you've
suffered. Everything you've enjoyed. Loved. Hated. Everything.`}</p>
    <p>{`In short, every event you experience and the context in which you experience
that event changes you. This may seem overdramatic but consider that every
memory you have has altered the neurons in your mind. Even recalling memory in a
sense changes you. You are rare. Your existence, as it stands, is the result of
an unfathomable number of material & processes over time. A number so large it
makes winning the lottery look laughably small.`}</p>
    <p>{`I think we get a sense of this at times in the form of deep loneliness. Words
have their limitations. The fidelity of our message is compromised as we try to
capture and express our experience in words. The accuracy of that message is
further lost when it's heard and interpreted by the listener. That isn't the
listener's fault, they are doing their best to imagine and comprehend what that
experience for you must be like but they too are unique and empathy is bounded
by the amount of overlap you each have. I think our messages and stories to
ourselves are often inaccurate as we try to interpret them. Even more so after
we've tried transferring that experience to another. I think Saint Paul
expressed this beautifully in Romans 8:26:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Likewise the Spirit also helpeth our infirmities: for we know not what we
should pray for as we ought: but the Spirit itself maketh intercession for us
with groanings which cannot be uttered."`}</p>
    </blockquote>
    <p>{`We all have that deep inner selves. The thoughts, feelings & experiences we
don't quite know how to put words to. The brokenness. The fears & doubts. The
hopes & dreams. It can be a horrifying thought to consider how helplessly
isolated that self is. Indeed one can feel deeply alone & misunderstood when
surrounded by their best friends.`}</p>
    <p>{`Hopefully I've been able to capture some small measure of the idea of your
uniqueness. I love how C.S. Lewis says it:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"There are no ordinary people. You have never talked to a mere mortal."`}</p>
    </blockquote>
    <p>{`I think it is, at least in part, your uniqueness that makes the worth of your
soul great in the sight of God. Christ won a perfect empathy through a perfect
atonement. Consider that you, particularly that `}<em parentName="p">{`you`}</em>{` you, that inner self, is
something that is only understood by Him. That understanding is something that
He bought by "suffering pains and afflictions and temptations of every kind" and
by taking on your "infirmities" so "that he may know according to the flesh how
to succor" you according to your "infirmities".`}</p>
    <p>{`And here is a thought that fills my heart with gratitude. When God heals you,
you will be able to love God in a way that only `}<em parentName="p">{`you`}</em>{` and `}<em parentName="p">{`He`}</em>{` truly understand.
In that way, you can love a part of God that no one else can. If you don't, no
one else will. That is a relationship and a love that He can only experience
with you precisely because there is no one like you. No one uniquely broken just
the way that you are and therefore, no one to be healed and loved just the way
that you are. No one to love Him in the way that only you can. No one that He
can share that experience, your experience, with. A loss that can't be replaced.`}</p>
    <p>{`When I thought about this a dark thought came over me. "So you're unique. That
doesn't mean you are good. It doesn't mean your worth is great." It is true that
being unique is not the same as being good, or loveable. However, it is not true
that our worth isn't great. Indeed, Jesus shattered that fear by paying the
price for us in advance. If our worth wasn't great or was only great conditioned
upon our obedience then He would have paid it upon those conditions. But He
didn't, He already paid it. He put His blood where His words are when He says,
"Your worth is great to me. I love you. See? I've already paid the price." And
if He know's the future or all possible variations of the future, then He must
also know that your suffering and His suffering will be worth it. (I say "if"
not as a limitation to His power but rather as a question of possibility.) In
that sense, He has already declared your worth. He has already said "I love
`}<em parentName="p">{`you`}</em>{`. That `}<em parentName="p">{`you`}</em>{` that only `}<em parentName="p">{`you`}</em>{` are." and it beckons out to us, to love Him
back. As iconically stated by John,`}</p>
    <blockquote>
      <p parentName="blockquote">{`"19 We love him, because he first loved us."`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      